<template>
  <div>
    <b-card no-body class="mb-0">
      <b-row class="m-2">
        <b-col sm="6" md="3" class="mb-1 mb-md-0">
          <v-select
            size="sm"
            v-model="pagination.perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pagination.perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block"
            @input="onChange"
          />
          <label class="mx-50">entries per page</label>
        </b-col>
        <b-col sm="7" md="7">
          <b-form-input
            v-model="search"
            class="d-inline-block mr-1 mb-1 mb-md-0"
            placeholder="Search..."
            @input="onChange"
          />
        </b-col>
        <b-col sm="5" md="2">
          <b-button
            block
            v-if="$can('create', 'user')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mb-1 mb-md-0"
            left
            variant="primary"
            @click="
              () => {
                $router.push('/user/form/new');
              }
            "
          >
            <span class="text-nowrap">Add User</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table
        bordered
        class="position-relative"
        :items="userList"
        responsive
        :fields="field"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(sr)="data">
          {{
            data.index + 1 + (pagination.currentPage - 1) * pagination.perPage
          }}
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <feather-icon
            v-if="$can('update', 'user')"
            icon="EditIcon"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary
            variant="outline-primary"
            title="Edit"
            class="mr-1"
            @click="$router.push(`/user/form/${data.item.user_id}`)"
          />
        </template>
      </b-table>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-end"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.total"
                :per-page="pagination.perPage"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                :limit="15"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import * as UserServices from "@/apiServices/UsersServices";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    vSelect,
    VBTooltip,
  },

  data() {
    return {
      userList: [],
      field: [
        { label: "SR", key: "sr", sortable: true, thStyle: { width: "6rem" } },
        { label: "User", key: "name", sortable: true },
        { label: "Email", key: "email", sortable: true },
        { label: "Mobile", key: "mobile", sortable: true },
        {
          label: "Role",
          key: "role_name",
          sortable: true,
        },
        // { label: "Company", key: "company", sortable: true },
        { key: "actions", thStyle: { width: "3rem" } },
      ],
      search: "",
      // pagination: {
      //   currentPage: 1,
      //   perPage: 10,
      //   total: 0,
      // },
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 10,
        total: 0,
        perPageOptions: [10, 25, 50, 100],
      },
    };
  },

  methods: {
    onChange() {
      this.$nextTick(() => {
        this.getAllUsers();
      });
    },

    async getAllUsers() {
      try {
        const response = await UserServices.getAllUsers({
          search: this.search,
          perPage: this.pagination.perPage,
          currentPage: this.pagination.currentPage,
        });

        if (response.data.status) {
          this.userList = response.data.data.data;
          if (response.data.data.pagination.total) {
            this.pagination.total = response.data.data.pagination.total;
          }
          this.pagination.currentPage =
            response.data.data.pagination.currentPage;
        }
      } catch (error) {
        console.log("Error in getAllUsers ", error);
      }
    },
  },
  beforeMount() {
    this.getAllUsers();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.per-page-selector {
  width: 90px;
}
</style>
