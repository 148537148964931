import Api from "./Api";

// Users
export const getAllUsers = (payload) =>
  Api().get(`/user/all`, { params: payload });

export const getSingleUser = (payload) =>
  Api().get(`/user/single/${payload.user_id}`);

// export const setUserStatus = (payload) => Api().post(`/user/status/${payload.status}/${payload.user_id}`)

export const saveUser = (payload) => Api().post(`/user/save`, payload);

// Roles
export const getAllRoles = (payload) =>
  Api().get(`/user/role/all`, { params: payload });

export const getRoleModuleMap = (payload) =>
  Api().get(`/user/role/module-map`, { params: payload });

export const getSingleRole = (payload) =>
  Api().get(`/user/role/single/${payload.role_id}`);

export const saveRole = (payload) => Api().post(`/user/role/save`, payload);
